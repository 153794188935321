/*
 *
 */
import * as React from 'react';
import Page from '../components/page';
import { Link } from "gatsby"

/*
 *
 */
const NotFoundPage = () => {
	return (
		<Page
			title="Page introuvable"
			intro="Désolé, la page que vous cherchez n'existe pas."
			content={(
				<React.Fragment>
					<p><Link to="/">Retour à la page d'accueil</Link></p>
				</React.Fragment>
			)}
	/>
	);
}

export default NotFoundPage
